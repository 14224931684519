import { render, staticRenderFns } from "./ContainerRF.vue?vue&type=template&id=48f4d777&scoped=true"
import script from "./ContainerRF.vue?vue&type=script&lang=js"
export * from "./ContainerRF.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f4d777",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/kmtcadmin/myagent/_work/15/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48f4d777')) {
      api.createRecord('48f4d777', component.options)
    } else {
      api.reload('48f4d777', component.options)
    }
    module.hot.accept("./ContainerRF.vue?vue&type=template&id=48f4d777&scoped=true", function () {
      api.rerender('48f4d777', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/trans/popup/ContainerRF.vue"
export default component.exports